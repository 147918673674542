<template>
  <el-upload :show-file-list="false" :limit="1" :http-request="request" :on-change="onChange">
    <template #trigger>
      <div class="img" v-if="imgUrl" v-loading="loading">
        <!-- <el-image :src="imgUrl" style="display: flex; align-items: center; justify-content: center;"/> -->
        <img :src="imgUrl" style="display: flex; align-items: center; justify-content: center; width: 120px;" >
      </div>
      <el-icon class="uploadIcon" v-else v-loading="loading">
        <span v-if="isShowMsg" style="color:#c45656">上传失败!</span>
        <span v-else>
          <Upload /><span style="font-size:10px">{{text}}</span>
        </span>
      </el-icon>
    </template>
  </el-upload>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'
import { Upload } from '@element-plus/icons-vue'
import api from '@/request/api.js'
import { ElMessage } from 'element-plus'

const props = defineProps(['img', 'text',"type",'idcard'])
const emit = defineEmits(["success"])

const imgUrl = ref('')
const text = ref('')
const loading = ref(false)
const isShowMsg = ref(false)

imgUrl.value = props.img ? props.img : ''
text.value = props.text ? props.text : ''

const request = (options) => {
  api.uploadImage({ file: options.file }, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((r) => {
      if (r.data.status == 200) {
        loading.value = false
        const url = URL.createObjectURL(options.file);
        imgUrl.value = url
        if (props.type==null || props.type == undefined || props.type=='') {
          emit("success", r.data.data.id, url);
        }else{
          ocr(r.data.data.id,url)
        }
      }
    }).catch(() => {
      loading.value = false
      isShowMsg.value = true
    })
}


async function ocr(imageData,url){
    const images= {file:await url2Base64(url),discern_type:props.type}
    if(props.type=='ID_CARD'){
      images.id_card_side=props.idcard
    }
    api.ocr(images).then(r=>{
      if (r.status==200) {
        emit("success", imageData, url,r.data);
      }else{
        ElMessage.error('图片识别失败')
      }
    })
}


function url2Base64(url,type = 'image/jpeg'){
  return new Promise((resolve, reject) => {
    const img = new Image()
    const canvas = document.createElement('canvas');
    img.crossOrigin = '*';
    img.onload = function () {
        const width = img.width, height = img.height;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, width, height);
        const base64 = canvas.toDataURL(type);
        resolve(base64);
    };
    img.onerror = function () {
        reject(new Error('message'));
    };
    img.src = url;
  });  
}

const onChange = (r) => {
  if (r.status == "ready") {
    isShowMsg.value = false
    loading.value = true
  }
}
</script>

<style lang="less">
.el-loading-spinner {
  margin-top: -42px !important;
}

.uploadIcon,
.img {
  font-size: 12px;
  color: rgb(91, 91, 91);
  padding: 6px;
  border: 1px dashed var(--el-border-color-darker);
  width: 120px;
}
</style>