<template>
  <el-table :data="tableData" style="width: 100%" v-loading="isloading" size="small">
    <el-table-column label="序号" width="80" type="index" align="center" />
    <el-table-column label="支付方式" prop="name" align="center" />
    <el-table-column label="支付方式编号" prop="code" align="center">
      <template #default="scoped">
        <el-tag size="large">{{scoped.row.code}}</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="状态" prop="status" align="center">
      <template #default="scoped">
        <el-switch v-model="scoped.row.status" :active-value="1" :inactive-value="0" @change="handleChangeStatus($event,scoped.row)" inline-prompt active-text="启用" inactive-text="禁用" size="large" />
      </template>
    </el-table-column>
    <el-table-column label=" 创建时间" prop="created_at" align="center">
      <template #default="scope">
        <span>{{dayjs(scope.row.created_at).format("YYYY-MM-DD")}}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template #default="scope">
        <el-link :underline="false" type="primary" @click="handleEditor(scope.row)">编辑</el-link>
      </template>
    </el-table-column>
    <template #empty>
      <el-empty description="空数据" :image-size="200" />
    </template>
  </el-table>
  <div class="pagination">
    <el-pagination background layout="total,prev, pager, next" :total=pageinfo.total />
  </div>
  <!-- modalDialog -->
  <el-dialog v-model="isShow" title="修改支付方式" width="40%">
    <el-form :model="modifyPayData" label-width="180px">
      <el-form-item label="LOGO：">
        <upload @success="uploadIdCardFront" text="logo" />
      </el-form-item>
      <el-form-item label="支付方式名称：">
        <el-input v-model="modifyPayData.name" disabled :placeholder="modifyPayData.name" />
      </el-form-item>
      <el-form-item label="编码：">
        <el-input v-model="modifyPayData.code" disabled :placeholder="modifyPayData.code" />
      </el-form-item>
      <div v-if="modifyPayData.code=='bankcard'">
        <el-form-item label="借记卡：">
          <el-row style="width:100%">
            <el-col :span="10">
              <el-input v-model="modifyPayData.cost.debitcard.cost" />
            </el-col>
            <el-col :span="2">
              <span class="percentage">%</span>
            </el-col>
            <el-col :span="10">
              <el-input v-model="modifyPayData.cost.debitcard.total" />
            </el-col>
            <el-col :span="2">
              <span class="percentage">%</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="信用卡：">
          <el-col :span="10">
            <el-input v-model="modifyPayData.cost.creditcard.cost" />
          </el-col>
          <el-col :span="2">
            <span class="percentage">%</span>
          </el-col>
        </el-form-item>
      </div>
      <div v-else-if="modifyPayData.code=='unionpay'">
        <el-form-item label="借记卡1000以下(含)：">
          <el-row style="width:100%">
            <el-col :span="10">
              <el-input v-model="modifyPayData.cost.debitcard.cost" />
            </el-col>
            <el-col :span="2">
              <span class="percentage">%</span>
            </el-col>
            <el-col :span="10">
              <el-input v-model="modifyPayData.cost.debitcard.total" />
            </el-col>
            <el-col :span="2">
              <span class="percentage">%</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="借记卡1000以上：">
          <el-row style="width:100%">
            <el-col :span="10">
              <el-input v-model="modifyPayData.cost.debitcard_outer.cost" />
            </el-col>
            <el-col :span="2">
              <span class="percentage">%</span>
            </el-col>
            <el-col :span="10">
              <el-input v-model="modifyPayData.cost.debitcard_outer.total" />
            </el-col>
            <el-col :span="2">
              <span class="percentage">%</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="信用卡1000以下(含)：">
          <el-col :span="10">
            <el-input v-model="modifyPayData.cost.creditcard.cost" />
          </el-col>
          <el-col :span="2">
            <span class="percentage">%</span>
          </el-col>
        </el-form-item>
        <el-form-item label="信用卡1000以上：">
          <el-col :span="10">
            <el-input v-model="modifyPayData.cost.creditcard_outer.cost" />
          </el-col>
          <el-col :span="2">
            <span class="percentage">%</span>
          </el-col>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="费率(%):">
          <el-col :span="24">
            <el-input v-model="modifyPayData.cost" />
          </el-col>
        </el-form-item>
      </div>
      <el-form-item label="状态：">
        <el-radio-group v-model="modifyPayData.status">
          <el-radio :label=1 size="large">启用</el-radio>
          <el-radio :label=0 size="large">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排序：">
        <el-input v-model="modifyPayData.sort" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isShow = false">取消</el-button>
        <el-button type="primary" @click="modifyPaymentStatus()">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import upload from "@/components/CustomUpload"
import { ref, onMounted, getCurrentInstance, reactive } from "vue"
import dayjs from "dayjs"
import { ElMessage } from 'element-plus'

const { appContext } = getCurrentInstance()

const isShow = ref(false)

let tableData = ref()

let pageinfo = reactive({
  total: 0
})

let isloading = ref(true)

let modifyPayData = ref({})

const uploadIdCardFront = (imgId, imgUrl) => {
  modifyPayData.value.logo = imgUrl
}

const handleChangeStatus = (e, row) => {
  let id = row.id
  if (e == 1) {
    appContext.config.globalProperties.$api.payment.payeditor(id, { status: 1 }).then(res => {
      if (res.data.status == 200) {
        ElMessage({
          message: '启用成功',
          type: 'success',
        })
      } else {
        e = 0
        ElMessage({
          message: '启用失败',
          type: 'error',
        })
      }
    })
  } else {
    appContext.config.globalProperties.$api.payment.payeditor(id, { status: 0 }).then(res => {
      if (res.data.status == 200) {
        ElMessage({
          message: '禁用成功',
          type: 'success',
        })
      } else {
        e = 1
        ElMessage({
          message: '禁用失败',
          type: 'error',
        })
      }
    })
  }
}

const handleEditor = (data) => {
  modifyPayData.value = { ...data }
  isShow.value = !isShow.value
}

const queryPayMethods = () => {
  isloading.value = true
  appContext.config.globalProperties.$api.payment.paymethods().then(res => {
    if (res.data.status == 200) {
      tableData.value = res.data.data
      pageinfo.total = res.data.data.length
      isloading.value = !isloading.value
    }
  })
}

const modifyPaymentStatus = () => {
  let id = modifyPayData.value.id
  let data = { ...modifyPayData.value }
  delete data.logo;
  appContext.config.globalProperties.$api.payment.payeditor(id, data).then(res => {
    if (res.data.status == 200) {
      isShow.value = !isShow.value
      queryPayMethods()
      ElMessage({
        message: '操作成功',
        type: 'success',
      })
    } else {
      queryPayMethods()
      isShow.value = !isShow.value
      ElMessage({
        message: '操作失败,请稍后重试',
        type: 'error',
      })
    }
  })
}

onMounted(() => {
  queryPayMethods()
})
</script>

<style lang="less" scoped>
.percentage {
  margin-left: 8px;
}

.pagination {
  margin: 1.25rem 0;
  display: flex;
  justify-content: flex-end;
}
</style>